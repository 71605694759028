import "$styles/index.css";

document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('a[href^="#"]')].forEach(el => {
    el.addEventListener('click', (event) => {
      const targetId = event.currentTarget.href.split('#')[1];
      const targetEl = document.getElementById(targetId);

      if (targetEl.length === 0) return;

      // Only prevent the browser's default action when the element is found.
      event.preventDefault();

      targetEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    });
  })
});
